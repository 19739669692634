@tailwind base;
@tailwind components;
@tailwind utilities;


.scroll-offset {
  scroll-margin-top: 80px;
}

.min-w-lg {
  width: 1025px;
}

.glass {
  background: rgba(255, 255, 255, 0.2);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
}

.glass.dark {
  background: rgba(14, 40, 65, 0.5);
}

.hero { 
  background-image: url('assets/images/banner.webp');
}

.carousel-item {
  display: none;
}

.carousel-item.active {
  display: block;
}

.carousel-item {
  background-size: cover;
  background-position: center;
  height: 100%;
  position: relative;
}

.carousel-caption {
  position: absolute;
  bottom: 0;
  right: 0;
  color: white;
  padding: 20px;
  background-color: rgba(14, 40, 65, 0.7);
}

.custom-duotone-icon {
  --fa-primary-color: #0E2841; 
  --fa-secondary-color: #EF4135; 
  --fa-secondary-opacity: 1;
  --fa-primary-opacity: 0.8; 
}

.custom-row:hover {
          transform: scale(1.2);
          transition: transform 0.3s ease;
      }

.flex-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}


.flex-logo {
  flex: 0 0 auto;
  width: 220px;
}

.marquee-container {
  overflow: hidden;
  width: 80%;
  margin: 0 auto;
}

@media (min-width: 768px) {
  .marquee-container {
      width: 40%;
  }
}

.marquee-track {
  display: flex;
  white-space: nowrap;
  will-change: transform;
}

.marquee-item {
  flex-shrink: 0;
  display: inline-block;
}

.marquee-item img {
  width: 96px; 
  height: auto;
}

.blur-border::before,
.blur-border::after {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  width: 20px;
  pointer-events: none;
  z-index: 10;
  background: linear-gradient(to right, transparent, rgba(14, 40, 65));
}

.blur-border::after {
  right: 0;
  left: auto;
  background: linear-gradient(to right, transparent, rgba(14, 40, 65));
}

.blur-border::before {
  left: 0;
  right: auto;
  background: linear-gradient(to left, transparent, rgba(14, 40, 65));
}

#contact_address b {
  display: none;
}